import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const InputForm = () => {
  const [section, setSection] = useState('');
  const [rollNumber, setRollNumber] = useState('');
  const [subjectCode, setSubjectCode] = useState(''); // New state for subjectCode
  const navigate = useNavigate();

  const handleContinue = () => {
    if (section && rollNumber && subjectCode) {  // Check for all fields
      navigate(`/camera?section=${section}&rollNumber=${rollNumber}&subjectCode=${subjectCode}`); // Include subjectCode in the URL
    } else {
      alert('Please fill in all fields!');
    }
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Enter Your Details</h1>
      <input
        type="text"
        placeholder="Enter Class Section"
        value={section}
        onChange={(e) => setSection(e.target.value)}
        style={{ padding: '10px', marginBottom: '20px', marginTop: '20px' }}
      />
      <br />
      <input
        type="text"
        placeholder="Enter Roll Number"
        value={rollNumber}
        onChange={(e) => setRollNumber(e.target.value)}
        style={{ padding: '10px', marginBottom: '20px' }}
      />
      <br />
      <input
        type="text"
        placeholder="Enter Subject Code"
        value={subjectCode}  // Bind the subjectCode state
        onChange={(e) => setSubjectCode(e.target.value)}  // Update the state
        style={{ padding: '10px', marginBottom: '20px' }}
      />
      <br />
      <button onClick={handleContinue} style={{ padding: '10px 20px', cursor: 'pointer' }}>
        Continue
      </button>
    </div>
  );
};

export default InputForm;
