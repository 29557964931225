import React, { useState, useEffect } from 'react';
import Webcam from 'react-webcam';
import { useLocation, useNavigate } from 'react-router-dom';
import { Html5QrcodeScanner } from "html5-qrcode"; // Correct import

const CameraCapture = () => {
  const [imageBase64, setImageBase64] = useState(null);
  const [qrCodeBro, setQRCodeBro] = useState(null);
  
  const [capturing, setCapturing] = useState(false);
  const [message, setMessage] = useState('');
  const [timestamp, setTimestamp] = useState(null);
  const [loading, setLoading] = useState(false); // State for loading animation
  const [qrScannerEnabled, setQrScannerEnabled] = useState(false);  // State to control QR scanner
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const section = queryParams.get('section');
  const rollNumber = queryParams.get('rollNumber');
  const SubjectCode = queryParams.get('subjectCode');

  const webcamRef = React.useRef(null);

  const captureImage = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImageBase64(imageSrc);
    setTimestamp(Date.now());
    setCapturing(false);
  }, [webcamRef]);

  const handleCaptureButton = () => {
    setCapturing(true);
  };

  const handleSubmit = async () => {
    if (!timestamp) {
      alert('Image not captured yet!');
      return;
    }

    const formData = new FormData();
    formData.append('PassCode', SubjectCode);
    formData.append('Section', section);
    formData.append('TimeStamp', timestamp.toString());
    formData.append('UID', rollNumber);

    if (qrScannerEnabled) {
    formData.append('IMG', setQRCodeBro);
    
    } else {
    const OnlyBase64String = imageBase64.split('base64,')[1];
    formData.append('IMG', OnlyBase64String);
    
    }
    
    
    try {
      setLoading(true); // Start loading animation

      const response = await fetch('https://romake.in/LinkRonak.php', {
        method: 'POST',
        body: formData,
        headers: {
          'Accept': 'application/json', // For receiving JSON response
        },
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      const responseData = await response.json();
      setMessage(responseData.Message || 'No message received');
      alert(responseData.Message);

      // Enable QR scanner if response contains "Success"
      if (responseData.Message && responseData.Message.includes("Face ID Verification Successfully Done")) {
        setQrScannerEnabled(true);
      } else {
        setQrScannerEnabled(false);
      }
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false); // Stop loading animation
    }
  };

  useEffect(() => {
    if (!section || !rollNumber) {
      navigate('/');
    }
  }, [section, rollNumber, navigate]);

  // Initialize QR code scanner when enabled
  useEffect(() => {
    if (qrScannerEnabled) {
      const scanner = new Html5QrcodeScanner("qr-reader", { fps: 10, qrbox: 250 });
      scanner.render(onScanSuccess, onScanFailure);
    }
  }, [qrScannerEnabled]);

  const onScanSuccess = (decodedText, decodedResult) => {
    // Handle QR code scanning success
    console.log(`QR code decoded: ${decodedText}`);
    setQRCodeBro(decodedText);
    
    //alert(decodedText);
    handleSubmit();
    // Put decoded text into form data 'IMG' if required
  };

  const onScanFailure = (error) => {
    // Handle QR code scanning failure
    console.log(`QR Code scanning failed: ${error}`);
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <h1>Camera Capture</h1>
      
      {/* Conditionally render webcam and buttons or QR scanner */}
      {!qrScannerEnabled ? (
        <div>
          <div>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width="100%"
              videoConstraints={{
                facingMode: 'user',
              }}
            />
          </div>
          <br />
          {!capturing ? (
            <button
              onClick={handleCaptureButton}
              className="animated-button"
              style={{ padding: '10px 20px', cursor: 'pointer' }}
            >
              Capture
            </button>
          ) : (
            <button
              onClick={captureImage}
              className="animated-button"
              style={{ padding: '10px 20px', cursor: 'pointer' }}
            >
              Capture Image
            </button>
          )}
          <br />
          {imageBase64 && (
            <div style={{ marginTop: '20px' }}>
              <img src={imageBase64} alt="Captured" style={{ maxWidth: '300px' }} />
            </div>
          )}
          <br />
          {imageBase64 && !loading && (
            <button
              onClick={handleSubmit}
              className="animated-button"
              style={{ padding: '10px 20px', cursor: 'pointer', marginTop: '20px' }}
            >
              Submit
            </button>
          )}

          {loading && (
            <div style={{ marginTop: '20px' }}>
              <div className="spinner"></div>
            </div>
          )}
        </div>
      ) : (
        // Only show the QR scanner
        <div id="qr-reader" style={{ marginTop: '20px' }}></div>
      )}
    </div>
  );
};

export default CameraCapture;
